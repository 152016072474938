import React, { FC, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { ButtonVariantEnum } from '../../utils/enums/button';
import { AppContext } from '../../store/AppContextProvider';
import Button from '../button/Button';
import BackArrow from '../../assets/icons/BackArrow.inline.svg';

import './TermsOfUse.scss';

const TermsOfUse: FC = () => {
    const { pageContent } = useContext(AppContext);

    useEffect(() => {
        document.querySelector('html')?.classList.remove('disable-scroll');
    }, []);

    return (
        <section className="terms-of-use">
            <div className="terms-of-use__wrapper">
                <Link className="terms-of-use__link" to="/map">
                    <Button
                        label="BACK"
                        leftIcon={<BackArrow />}
                        type={ButtonVariantEnum.SECONDARY}
                        onClick={() => {}}
                    />
                </Link>
                <p className="terms-of-use__title">{pageContent?.termsOfUse.heading}</p>
                <hr className="terms-of-use__line" />
                {pageContent?.termsOfUse.sections.map((section) => {
                    return (
                        <>
                            {section.title && <p className="terms-of-use__subtitle">{section.title}</p>}
                            <p className="terms-of-use__text">{section.description}</p>
                            <br />
                        </>
                    );
                })}
            </div>
        </section>
    );
};

export default TermsOfUse;
