import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout/Layout";
import TermsOfUse from "../components/termsOfUse/TermsOfUse";

const TermsOfUsePage: FC = () => {
    return (
        <>
            <Helmet title="Get Real - Got Milk. | Terms of use" />
            <Layout pageClassName="terms-of-use-page">
                <TermsOfUse />
            </Layout>
        </>
    );
}

export default TermsOfUsePage;